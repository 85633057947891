import { Injectable } from '@angular/core';
import { BlacklistIPRequest } from '@app/shared/interfaces/blacklist-ip/blacklist-ip.request';
import { BlacklistIP } from '@app/shared/interfaces/blacklist-ip/blacklist-ip.response';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class BlacklistIPService extends AbstractCrudService<
  BlacklistIP,
  BlacklistIPRequest
> {
  protected override feature = 'blacklist-ips';
  protected override endPoint = `/blacklist-ips/`;
}
